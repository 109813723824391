<script setup lang="ts">
defineProps({
  legalName: String,
  logoImageUrl: String,
  size: Number,
  file: File,
  filePreview: String
})
const getInitials = (str: string) => {
  return str
    .split(/[\s-_]+/)
    .slice(0, 2)
    .map(word => word.charAt(0).toUpperCase())
    .join('');
}
</script>

<template>
  <div :style="{width: size + 'px', height: size + 'px', lineHeight: size + 'px'}">
    <v-img
      v-if="file || logoImageUrl"
      :src="file ? filePreview : logoImageUrl"
      cover
      :height="size"
      :width="size"
      class="rounded-circle"
    />
    <span v-else> {{ getInitials(legalName ?? '') }}</span>
  </div>
</template>

<style scoped lang="scss">
div {
  color: $grey-text;
  background: $white-grey;
  font-size: 20px;
  font-weight: 500;
  border-radius: 50%;
  text-align: center;
}
</style>
